import {FC} from "react"

import CloseIcon from "public/icons/close.svg"

interface Props {
  onClick: VoidFunction
  className?: string
}

const CloseButton: FC<Props> = ({onClick, className}) => (
  <button type="button" onClick={onClick} className={className}>
    <CloseIcon />
  </button>
)

export default CloseButton
