import {useRouter} from "next/router"
import type {FC} from "react"

import cn from "classnames"

import BackIcon from "public/icons/chevron-left.svg"

interface Props {
  href?: string
  className?: string
  onClick?: VoidFunction
}

const BackButton: FC<Props> = ({className, href, onClick}) => {
  const router = useRouter()

  const goBack = () => (href ? router.push(href) : router.back())

  return (
    <button
      type="button"
      className={cn("flex text-lg items-stretch no-underline py-5", className)}
      onClick={onClick ?? goBack}
    >
      <div className="flex items-center justify-center">
        <BackIcon />
      </div>

      <div className="flex items-center">Back</div>
    </button>
  )
}

export default BackButton
