import {FC} from "react"
import {useRouter} from "next/router"
import {signIn} from "next-auth/react"
import {logger} from "@gooditworks/monitoring"

import {withLayout} from "components/Layout"
import LoginForm, {LoginFormFields} from "components/LoginForm"
import FormLayout from "components/FormLayout"

const LoginPage: FC = () => {
  const router = useRouter()

  const onSubmit = (fields: LoginFormFields) => {
    const options = {
      redirect: false,
      email: fields.email,
      callbackUrl: `${window.location.origin}/api/callback/email`
    }

    signIn("email", options)
      .then(() => router.push("/magic-link-sent"))
      .catch(error => logger.captureException(error))
  }

  return (
    <FormLayout title="Welcome to Yung">
      <LoginForm onSubmit={onSubmit} className="mt-12 w-full max-w-button tablet:mt-16" />
    </FormLayout>
  )
}

export default withLayout(LoginPage)
