import {ComponentType, FC} from "react"

import Layout, {Props as LayoutProps} from "./Layout"

const withLayout = <P extends Record<string, any>>(
  Component: ComponentType<P>,
  layoutProps?: LayoutProps
) => {
  const Wrapped: FC<P> = props => (
    <Layout isPage={layoutProps?.isPage}>
      <Component {...props} />
    </Layout>
  )

  Wrapped.displayName = Component.displayName

  return Wrapped
}

export default withLayout
