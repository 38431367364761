import {useState, HTMLProps, forwardRef, Ref} from "react"
import cn from "classnames"

interface Props extends HTMLProps<HTMLInputElement> {
  title?: string
  error?: string
  className?: string
}

const TextInput = (props: Props, ref: Ref<HTMLInputElement>) => {
  const {title, error, className, ...rest} = props

  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const containerCn = cn("flex flex-col max-w-button", className)

  const inputCn = cn(
    "bg-text-white border-0 border-b-2 border-black-500 outline-none transition text-body-4 mt-2 disabled:text-text-gray-2",
    {
      "border-black-900": focused,
      "border-red-warning": !!error
    }
  )

  const titleCn = cn("text-caption-1 transition", {
    "text-black-500": !error && !focused,
    "text-black-900": !error && focused,
    "text-red-warning": !!error
  })

  const errorCn = cn("transition text-caption-1 text-red-warning mt-2 opacity-0", {
    "opacity-100": !!error
  })

  return (
    <div className={containerCn}>
      {title && <span className={titleCn}>{title}</span>}
      <input {...rest} ref={ref} onFocus={onFocus} onBlur={onBlur} className={inputCn} />
      <span className={errorCn}>{error || "&nbsp"}</span>
    </div>
  )
}

export default forwardRef(TextInput)
