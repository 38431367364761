import {Control, FieldValues, useFormState, useWatch} from "react-hook-form"

// Хук, который определяет состояние кнопки Submit формы (disabled или нет)
const useSubmitDisabled = <T extends FieldValues>(control: Control<T>) => {
  const values = useWatch({control})
  const {isDirty, isSubmitting, isSubmitSuccessful} = useFormState({control})

  const isSomeFieldEmpty = Object.values(values).some(fieldValue => fieldValue === "")

  return !isDirty || isSubmitting || isSubmitSuccessful || isSomeFieldEmpty
}

export default useSubmitDisabled
