/* eslint-disable react/button-has-type */

import {FC, HTMLProps} from "react"
import cn from "classnames"

interface Props extends HTMLProps<HTMLButtonElement> {
  className?: string
  type?: "button" | "submit"
}

const LinkButton: FC<Props> = ({children, className, type = "button", ...rest}) => (
  <button
    type={type}
    className={cn(
      "text-body-4 underline text-text-gray hover:text-text-black transition-colors",
      className
    )}
    {...rest}
  >
    {children}
  </button>
)

export default LinkButton
