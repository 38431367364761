import {FC, ReactNode} from "react"

interface Props {
  title: string
  children: ReactNode
}

const FormLayout: FC<Props> = ({title, children}) => (
  <div className="my-12 flex flex-col tablet:my-20 mobile:items-center">
    <h4 className="mx-5 mobile:min-w-button tablet:text-headline-1 tablet:mx-0">
      {title}
    </h4>
    <div className="mx-5 mobile:min-w-button tablet:mx-2">{children}</div>
  </div>
)

export default FormLayout
