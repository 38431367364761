import {useState, ReactElement, ReactNode} from "react"

interface ModalProps {
  isOpened: boolean
  close: VoidFunction
  children?: ReactNode
}

type CreateModalFn = (props: ModalProps) => ReactElement

const useModal = (createModal: CreateModalFn) => {
  const [isOpened, setIsOpened] = useState(false)

  const showModal = () => {
    setIsOpened(true)

    window.document.body.style.overflow = "hidden"
  }
  const closeModal = () => {
    setIsOpened(false)

    window.document.body.style.overflow = "auto"
  }

  const modal = createModal({isOpened, close: closeModal})

  return [modal, showModal, closeModal] as const
}

export default useModal
export type {ModalProps}
