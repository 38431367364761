/* eslint-disable react/button-has-type */

import {HTMLProps, FC} from "react"
import cn from "classnames"

type HtmlButtonProps = HTMLProps<HTMLButtonElement>

interface Props extends HtmlButtonProps {
  type?: "button" | "submit"
  link?: boolean
  secondary?: boolean
  mini?: boolean
  className?: string
}

const Button: FC<Props> = ({
  link,
  secondary,
  mini,
  type = "button",
  className,
  ...rest
}) => {
  const classname = cn(
    "w-full max-w-button py-4 disabled:bg-black-500 transition-colors",
    {
      "bg-black-900 text-text-white": !link && !secondary,
      "text-text-black": link || secondary,
      "border-2 border-black-500 hover:bg-black-300": secondary,
      "w-auto text-caption-1 px-5 py-4": mini
    },
    className
  )

  return <button {...rest} type={type} className={classname} />
}

export default Button
