import {FC, ReactNode} from "react"

import cn from "classnames"

import TabletFrame from "./TabletFrame"

import styles from "./layout.module.css"

interface Props {
  isPage?: boolean
  isBigFrame?: boolean
  children?: ReactNode
}

const Layout: FC<Props> = ({isPage, isBigFrame, children}) => (
  <div
    className={cn(
      "min-w-screen min-h-screen bg-black-200 flex tablet:items-center tablet:justify-center",
      styles.container
    )}
  >
    <TabletFrame medium={isPage} big={isBigFrame}>
      {children}
    </TabletFrame>
  </div>
)

export type {Props}
export default Layout
