import {FC} from "react"
import {useForm} from "react-hook-form"

import {TextInput} from "components/form"
import {Button} from "components/button"
import {useSubmitDisabled} from "lib/hooks"
import {validation} from "components/RegisterForm/form"

import {LoginFormFields} from "./form"

interface Props {
  onSubmit: (fields: LoginFormFields) => void
  className?: string
}

const LoginForm: FC<Props> = ({onSubmit, className}) => {
  const form = useForm<LoginFormFields>({reValidateMode: "onSubmit"})
  const {handleSubmit, register, formState, control} = form
  const {errors} = formState

  const isSubmitDisabled = useSubmitDisabled(control)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <TextInput
        type="email"
        placeholder="Email"
        title="Your Email"
        error={errors.email?.message}
        {...register("email", validation.email)}
      />
      <Button type="submit" disabled={isSubmitDisabled} className="mt-6">
        Continue
      </Button>
    </form>
  )
}

export default LoginForm
