import {RegisterOptions} from "react-hook-form"

import {client} from "datasources/trpc"

interface RegisterFormFields {
  name: string
  email: string
  promocode: string
}

const required = {value: true, message: "This field is required"}

const validation: Record<string, RegisterOptions> = {
  name: {
    required,
    minLength: {value: 2, message: "From 2 characters"},
    maxLength: {value: 48, message: "To 48 characters"}
  },
  email: {
    required,
    pattern: {value: /^\S+@\S+\.\S+$/, message: "Invalid Email"}
  },
  promocode: {
    required,
    validate: async (value: string) => {
      const isCorrect = await client.verifyPromocode.query({code: value})

      return isCorrect || "Invalid code, please try again"
    }
  }
}

export type {RegisterFormFields}
export {validation}
