import {FC, ReactNode} from "react"
import cn from "classnames"

interface Props {
  medium?: boolean
  big?: boolean
  className?: string
  children: ReactNode
}

const TabletFrame: FC<Props> = ({medium, big, children, className}) => {
  const classname = cn(
    "w-full bg-text-white tablet:w-tablet-frame",
    {
      "tablet-big:w-tablet-big-frame": big,
      "tablet:w-full min-h-screen max-w-tablet-medium-frame": medium,
      "max-h-screen": !big && !medium
    },
    className
  )

  return <div className={classname}>{children}</div>
}

export default TabletFrame
