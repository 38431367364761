/* eslint-disable react/button-has-type */
import {FC, HTMLProps} from "react"
import cn from "classnames"

import styles from "./ArrowButton.module.css"

interface Props extends HTMLProps<HTMLButtonElement> {
  className?: string
  type?: "button" | "submit"
}

const ArrowButton: FC<Props> = ({children, className, type = "button", ...rest}) => (
  <button type={type} className={cn(styles.container, className)} {...rest}>
    <h6>{children}</h6>
  </button>
)

export default ArrowButton
