import {usePageStateMutate} from "lib/pageState"
import {PlanActivity} from "lib/scheduler"
import {isOpenPoll} from "lib/utils/isOpenPoll"
import {DashboardDataProps} from "server/dataFetchers/dashboard"

const useDashboardActivityComplete = (activity: PlanActivity) => {
  const mutateDashboard = usePageStateMutate<DashboardDataProps>("dashboard")

  const completeActivity = (
    p?: {newState?: string, success: boolean}
  ) => mutateDashboard(props => {
    const courseStatus = p?.newState && p.newState !== props.courseStatus
      ? p.newState
      : props.courseStatus

    if (
      isOpenPoll(activity) // Для открытых опросов
      && activity.postActivityId // Где есть следующий опрос
      && props.activities // И он присутствует в списке активити в стейте дашборда
      // eslint-disable-next-line
      && !!props.activities.find(a => a.id == activity.postActivityId)
    ) {
      const newActivities = props.activities.map(dashboardActivity => {
        // eslint-disable-next-line
        if (dashboardActivity.id == activity.postActivityId) {
          return {
            ...dashboardActivity,
            hidden: false,
          }
          // eslint-disable-next-line
        } if (dashboardActivity.id == activity.id) {
          return {
            ...dashboardActivity,
            hidden: true,
            completed: true
          }
        }

        return dashboardActivity
      })

      return {
        ...props,
        activities: newActivities,
        courseStatus
      }
    }

    return {
      ...props,
      courseStatus,
      activities: props.activities?.map(dashboardActivity => {
        if (activity.id === dashboardActivity.id) {
          return {...dashboardActivity, completed: true}
        }

        return dashboardActivity
      }) || null
    }
  })

  return completeActivity
}

export default useDashboardActivityComplete
